class DocumentForm {
    constructor() {
        // Inicializace při vytvoření instance
        this.initDocumentItem();
    }

    initDocumentItem() {
        // Načtení inputů pro položky faktury
        const documentItemCount = document.getElementById('document-item-count');
        const documentItemPrice = document.getElementById('document-item-price');
        const documentItemPriceBase = document.getElementById('document-item-price-base');

        if (documentItemCount && documentItemPrice && documentItemPriceBase) {
            documentItemPrice.addEventListener('input', () => {
                this.updateDocumentItemPrice(documentItemCount, documentItemPrice, documentItemPriceBase);
            });
        }
    }

    updateDocumentItemPrice(documentItemCount, documentItemPrice, documentItemPriceBase) {
        const itemCount = parseInt(documentItemCount.value, 10);
        const price = parseFloat(documentItemPrice.value);

        if (!isNaN(itemCount) && !isNaN(price)) {
            const priceBase = itemCount * price;

            documentItemPriceBase.value = priceBase.toFixed(2);
        }
    }

    static initialize() {
        // Vytvoření instance třídy pouze pokud jsou v DOM potřebné elementy
        new DocumentForm();
    }
}

// Inicializace při načtení DOM
document.addEventListener('DOMContentLoaded', () => {
    DocumentForm.initialize();
});

// Připojení na události Naja pro AJAXové operace
naja.addEventListener('complete', () => {
    DocumentForm.initialize();
});

export default DocumentForm;