class InventoryForm {
    constructor() {
        this.initInventoryItem();
        this.initInventoryItemAutocomplete();

        this.lastQuery = ''; 
        this.activeRequest = null; 
        this.isLoading = false; 
        this.debounceTimer = null; 
    }

    initInventoryItem() {
        // Načtení inputů pro položky faktury
        const inventoryItemCount = document.getElementById('inventory-item-count');
        const inventoryItemPriceSale = document.getElementById('inventory-item-price-sale');
        const inventoryItemPricePurchase = document.getElementById('inventory-item-price-purchase');
        const inventoryItemPricePurchaseBase = document.getElementById('inventory-item-price-purchase-base');

        if (inventoryItemCount && inventoryItemPriceSale && inventoryItemPricePurchase && inventoryItemPricePurchaseBase) {
            inventoryItemCount.addEventListener('input', () => {
                this.updateInventoryItemPrice(inventoryItemCount, inventoryItemPriceSale, inventoryItemPricePurchase, inventoryItemPricePurchaseBase);
            });
        }
    }

    updateInventoryItemPrice(inventoryItemCount, inventoryItemPriceSale, inventoryItemPricePurchase, inventoryItemPricePurchaseBase) {
        const itemCount = parseInt(inventoryItemCount.value, 10);
        const priceSale = parseFloat(inventoryItemPriceSale.value);
        const pricePurchase = parseFloat(inventoryItemPricePurchase.value);

        if (!isNaN(itemCount) && !isNaN(pricePurchase)) {
            const pricePurchaseBase = itemCount * pricePurchase;

            inventoryItemPricePurchaseBase.value = pricePurchaseBase.toFixed(2);
        }
    }

    initInventoryItemAutocomplete() {
        const input = document.getElementById('inventory-item-name');

        if (input) {
            let suggestionsContainer = document.getElementById('suggestions-container');
            if (!suggestionsContainer) {
                suggestionsContainer = document.createElement('div');
                suggestionsContainer.id = 'suggestions-container';
                suggestionsContainer.classList.add('list-group');
                suggestionsContainer.style.position = 'absolute';  
                suggestionsContainer.style.zIndex = '9999';  
                suggestionsContainer.style.width = `450px`;  
                suggestionsContainer.style.height = `35vh`;  

                document.body.appendChild(suggestionsContainer);
            }

            const loadSuggestions = () => {
                clearTimeout(this.debounceTimer);  
                this.debounceTimer = setTimeout(() => {
                    const query = input.value;
                    const provide = input.getAttribute('data-provide');

                    if (query.length > 2 && query !== this.lastQuery && !this.isLoading) {
                        this.lastQuery = query;
                        this.isLoading = true;

                        // Zrušení předchozího requestu, pokud existuje
                        if (this.activeRequest) {
                            this.activeRequest.abort(); 
                        }

                        input.classList.add('loading');

                        const inputRect = input.getBoundingClientRect();
                        suggestionsContainer.style.top = `${inputRect.bottom + window.scrollY}px`;
                        suggestionsContainer.style.left = `${inputRect.left + window.scrollX}px`;
                        suggestionsContainer.style.width = `${inputRect.width * 4}px`;

                        this.activeRequest = naja.makeRequest('POST', provide, { query: query }, { history: false })
                            .then(response => {
                                this.displaySuggestions(response, query);
                                input.classList.remove('loading');
                                this.isLoading = false;
                                this.activeRequest = null; 
                            })
                            .catch(() => {
                                input.classList.remove('loading');
                                this.isLoading = false;
                                this.activeRequest = null; 
                            });
                    }
                }, 300);  // Debounce čas (300 ms)
            };

            input.addEventListener('keyup', loadSuggestions);

            input.addEventListener('blur', () => {
                setTimeout(() => {
                    suggestionsContainer.innerHTML = '';
                    suggestionsContainer.style.display = 'none';
                }, 200);
            });
        }
    }

    displaySuggestions(suggestions, query) {
        const suggestionsContainer = document.getElementById('suggestions-container');
        suggestionsContainer.innerHTML = '';  
        suggestionsContainer.style.display = 'flex';

        for (let id in suggestions) {
            if (suggestions.hasOwnProperty(id)) {
                const productName = suggestions[id].name;
                const pricePurchase = suggestions[id].pricePurchase;
                const priceSale = suggestions[id].priceSale;
                const productId = id;
                const highlighted = this.highlightMatch(productName, query);

                const item = document.createElement('a');
                item.classList.add('list-group-item', 'list-group-item-action');
                item.innerHTML = highlighted;

                console.log(productName + " - " + productId);

                item.addEventListener('click', () => {
                    document.getElementById('inventory-item-name').value = productName;
                    document.getElementById('inventory-item-store-card').value = productId;
                    document.getElementById('inventory-item_add-store-card').value = productId;
                    document.getElementById('inventory-item-price-purchase').value = pricePurchase;
                    document.getElementById('inventory-item-price-sale').value = priceSale;
                    suggestionsContainer.innerHTML = ''; 
                    suggestionsContainer.style.display = 'none';
                });

                suggestionsContainer.appendChild(item);
                suggestionsContainer.style.display = 'flex';
            }
        }
    }

    highlightMatch(text, query) {
        const regex = new RegExp(`(${query})`, 'gi');
        return text.replace(regex, '<strong>$1</strong>'); 
    }

    static initialize() {
        // Vytvoření instance třídy pouze pokud jsou v DOM potřebné elementy
        new InventoryForm();
    }
}

// Inicializace při načtení DOM
document.addEventListener('DOMContentLoaded', () => {
    InventoryForm.initialize();
});

// Připojení na události Naja pro AJAXové operace
naja.addEventListener('complete', () => {
    InventoryForm.initialize();
});

export default InventoryForm;